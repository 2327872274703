body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: "url(/data/bg.jpg)";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200&family=Prompt:wght@200&family=Sarabun:wght@200&display=swap');
.sv-title {
  font-family: 'Mitr'!important;
}
.sv-question,
.sv-btn,
.sv-container-modern {
  font-family: 'Prompt'!important;
}

.sv-root-modern {
  background-color: white;
  border-radius: 5px;
  opacity: 0.965;
  margin: 25px 0px 25px 0px;
}

.sv-container-modern__title h3 {
  font-size: 40px!important;
}
  .sv-container-modern__title h5 {
  color: #000000;
  font-family: 'Sarabun'!important;
  font-size: 20px;
  line-height: 1.5em;
  padding-top: 20px;
}

.sv-description {
  color: rgba(64, 64, 64, 1)!important;
}

.sv-table__cell, .sv-multipletext__item-title {
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .sv-root-modern {
    margin: 20px 0px 20px 0px;
  }
  .sv-matrixdropdown .sv-item__control-label::before {
    content: attr(title);
  }
  .sv-matrixdropdown td:first-child {
    background-color:rgb(241, 241, 241);
    border-bottom: 1px solid #ddd;
  }
  .sv-matrixdropdown .sv-table__cell:first-child {
    padding: 10px 10px 10px 10px!important;
    margin-bottom: 10px;
    /* padding: 0px 10px!important; */
    /* padding-bottom: 0px!important; */
  }
  .sv-matrixdropdown .sv-table__cell:not(:first-child) {
    /* padding: 10px 0px 10px 20px!important; */
    padding: 0px 10px!important;
    /* padding-bottom: 0px!important; */
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrixdropdown td:before {
    display: none;
  }
  .sv-question__content {
    margin-left: 0em!important;
  }
  .sv-table__cell {
    font-weight: 700;
  }
}

img.bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.75;
}

.sv-checkbox--allowhover:hover .sv-checkbox__svg {
  border: solid 3px transparent!important;
  border-color: rgba(64, 64, 64, 0.5)!important;
  background-color: rgb(243, 243, 243)!important;
}

@media (hover: none) {
  .sv-checkbox--allowhover:hover .sv-checkbox__svg {
    border: solid 3px transparent!important;
    border-color: rgba(64, 64, 64, 0.5)!important;
    background-color: transparent!important;
    fill: none!important;
  }
}